<template>
  <v-dialog persistent :value="deleteExternalUserDialog" max-width="500">
      <v-card>
          <v-card-title class="headline">
              <span>Eliminar usuario externo</span>
          </v-card-title>
          <v-card-text>
              <v-container>
                  <v-row>
                    <v-col cols="6" >
                        <v-text-field
                          :value="dialogValuesExternalUser.id"
                          label="ID"
                          outlined
                          readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                          :value="dialogValuesExternalUser.username"
                          label="Nombre de usuario"
                          outlined
                          readonly
                        ></v-text-field>
                    </v-col>
                  </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                color="red"
                text
                @click="closeExternalUserDeleteDialog();resetValuesDialogExternalUser()"
              >Close</v-btn>
              <v-btn
                color="green"
                text
                @click="deleteExternalUser()"
              >Delete</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  computed: {
    ...mapState("ExternalUsers", ["dialogValuesExternalUser","deleteExternalUserDialog"])
  },
  methods:{
    ...mapMutations("ExternalUsers", ["setUsername","setPassword","setRepeatedPassword","resetValuesDialogExternalUser","closeExternalUserDeleteDialog"]),
    ...mapActions("ExternalUsers", ["deleteExternalUser"]),
  }

}
</script>