<template>
  <v-dialog
    :value="editInternalUserDialog.dialog"
    max-width="600px"
    :persistent="true"
  >
    <v-card>
      <v-card-title class="headline">Editar Usuario</v-card-title>
      <v-card-subtitle class="mt-1">
        USUARIO: {{ editInternalUserDialog.username }}
      </v-card-subtitle>
      <v-card-subtitle>
        CORREO: {{ editInternalUserDialog.email }}
      </v-card-subtitle>
      <v-col cols="12">
        <PasswordField
          :Dark="false"
          :Rules="editInternalUserDialog.passwordRules"
          Message="Min 8 characters"
          :Value="editInternalUserDialog.password"
          Label="Nueva contraseña"
          :Fn="setNewPasswordEditInternalUserDialog"
        />
      </v-col>
      <v-col cols="12">
        <PasswordField
          :Dark="false"
          :Rules="editInternalUserDialog.passwordRules"
          Message="Min 8 characters"
          :Value="editInternalUserDialog.repeatedPassword"
          Label="Repetir contraseña"
          :Fn="setRepeatedPasswordEditInternalUserDialog"
        />
      </v-col>
      <v-col cols="12">
        <v-radio-group
          :value="editInternalUserDialog.isAdmin"
          label="¿Es administrador?"
          @change="setIsAdminEditInternalUserDialog"
          row
        >
          <v-radio label="Si" value="1"></v-radio>
          <v-radio label="No" value="0"></v-radio>
        </v-radio-group>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="editInternalUser"
          >Actualizar</v-btn
        >
        <v-btn color="blue darken-1" text @click="closeEditInternalUserDialog();resetEditInternalUserDialog()"
          >Cerrar</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PasswordField from "../../StandardizedComponents/passwordFields";

export default {
  components: {
    PasswordField,
  },
  computed: {
    ...mapState("InternalUsers", ["editInternalUserDialog"]),
  },
  methods: {
    ...mapMutations("InternalUsers", [
      "setNewPasswordEditInternalUserDialog",
      "setRepeatedPasswordEditInternalUserDialog",
      "closeEditInternalUserDialog",
      "setIsAdminEditInternalUserDialog",
      "resetEditInternalUserDialog",
    ]),
    ...mapActions("InternalUsers", ["editInternalUser"])
  },
};
</script>