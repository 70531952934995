<template>
  <div id="app">
      <v-app id="inspire">
        <Navbar/>
          <v-tabs
            background-color="primary"
            dark
          >
            <v-tab>Internos</v-tab>
            <v-tab>Externos</v-tab>
          


            <v-tab-item key="1">
                <TableUsers/>
            </v-tab-item>
            <v-tab-item >
                <TableExtUsers key="2"/>
            </v-tab-item>

        </v-tabs>
      </v-app>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import TableUsers from '../components/Tables/TableUsers';
import TableExtUsers from '../components/Tables/TableExtUsers';
export default {
    components:{
        Navbar,
        TableUsers,
        TableExtUsers
    }
}
</script>

<style scoped>

</style>