<template>
  <div id="app">
    <v-app id="inspire">
      <v-data-table
        :headers="internalUsersTable.headers"
        :items="internalUsersTable.usersTable"
        item-key="id_user"
        class="elevation-1"
        :loading="internalUsersTable.loading"
        loading-text="Cargando..."
        no-data-text="No data found"
        dense
        :search="internalUsersTable.search"
      >
        <template v-slot:top>
            <v-toolbar flat>
                <v-row>
                <v-spacer></v-spacer>
                <SearchBar :setSearchTerm="setTableSearchTerm"/> 
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:item.acciones="{ item }">
          <TableButtons
            :Buttons="[
                {
                    icon:'mdi-pencil',
                    Fn:showEditInternalUserDialog,
                    fnParams:item
                },
                {
                    icon:'mdi-delete',
                    Fn:showDeleteInternalUserDialog,
                    fnParams:item
                } 
            ]"
          />
        </template>
      </v-data-table>
      <DialogAdd/>
      <DialogDelete/>
      <DialogEdit/>
      <FAB :buttons="[{
          fn: showAddInternalUserDialog,
          color:'primary',
          icon: 'mdi-plus',
          name: 'agregar usuario',
        }]"/>
    </v-app>
  </div>
</template>
<script>
import {  mapActions, mapMutations, mapState } from "vuex";
import TableButtons from '../Complements&Buttons/tableButtons'
import DialogDelete from  '../Dialogs/UsersDialogs/dialogDelete'
import DialogEdit from  '../Dialogs/UsersDialogs/dialogEdit'
import DialogAdd from  '../Dialogs/UsersDialogs/dialogAdd'
import SearchBar from '../Complements&Buttons/searchBar'
import FAB from '../Complements&Buttons/fab'

export default {
  data: () => ({
    hidden: false,
  }),
  components: {
    TableButtons,
    DialogDelete,
    DialogEdit,
    DialogAdd,
    SearchBar,
    FAB,
  },
  computed: {
    ...mapState("InternalUsers", ["internalUsersTable"]),
  },

  methods: {
    ...mapActions("InternalUsers", ["fetchInternalUserTable"]),
    ...mapMutations("InternalUsers",["setTableSearchTerm","showEditInternalUserDialog","showDeleteInternalUserDialog", "showAddInternalUserDialog"]),
  },
  beforeMount() {
    this.fetchInternalUserTable()
  },
};
</script>