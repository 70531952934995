<template>
  <v-dialog :value="addInternalUserDialog.dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Agregar Usuario</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <TextField
                :Value="addInternalUserDialog.name"
                Label="Nombre"
                Type="text"
                :Fn="setAddInternalUserDialogName"
                :Dark="false"
              />
            </v-col>
            <v-col cols="12">
              <TextField
                :Value="addInternalUserDialog.email"
                Label="Correo electrónico"
                Type="text"
                :Fn="setAddInternalUserDialogEmail"
                :Dark="false"
              />
            </v-col>
            <v-col cols="12">
              <PasswordField
                :Value="addInternalUserDialog.password"
                Label="Contraseña"
                Type="text"
                :Fn="setAddInternalUserDialogPassword"
                :Dark="false"
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                :value="addInternalUserDialog.isAdmin"
                label="¿Es administrador?"
                color="primary"
                hide-details
                @change="setAddInternalUserDialogIsAdmin"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="
            closeAddInternalUserDialog();
            resetAddInternalUserDialog();
          "
        >
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="addInternalUser">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PasswordField from "../../StandardizedComponents/passwordFields.vue";
import TextField from "../../StandardizedComponents/textFields";

export default {
  components: {
    TextField,
    PasswordField,
  },
  methods: {
    ...mapMutations("InternalUsers", [
      "setAddInternalUserDialogName",
      "setAddInternalUserDialogEmail",
      "setAddInternalUserDialogPassword",
      "setAddInternalUserDialogPassword",
      "setAddInternalUserDialogIsAdmin",
      "closeAddInternalUserDialog",
      "resetAddInternalUserDialog",
    ]),
    ...mapActions("InternalUsers",["addInternalUser"])
  },
  computed: {
    ...mapState("InternalUsers", ["addInternalUserDialog"]),
  },
};
</script>