<template>
  <v-dialog persistent :value="addExternalUserDialog" max-width="500">
      <v-card>
          <v-card-title class="headline">
              <span>Agregar usuario externo</span>
          </v-card-title>
          <v-card-text>
              <v-container>
                  <v-row>
                      <v-col cols="12" sm="12" md="12">
                          <TextField 
                          :Value="dialogValuesExternalUser.username"
                          Label="Nombre de usuario"
                          Type="text"
                          :Fn="setUsername"
                          :Dark="false"/>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                          <PasswordField 
                          :Rules="passwordRules" 
                          Message="Min 8 characters" 
                          :Value="dialogValuesExternalUser.password" 
                          Label="Contraseña" :Fn="setPassword"/>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                          <PasswordField 
                          :Dark="false"
                          :Rules="passwordRules" 
                          Message="Min 8 characters" 
                          :Value="dialogValuesExternalUser.repeatedpassword" 
                          Label="Repetir contraseña" :Fn="setRepeatedPassword"/>
                      </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-btn outlined color="primary" absolute @click="generateRandomPassword()">
                      Crear password aleatorio
                    </v-btn>
                  </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                color="red"
                text
                @click="closeExternalUserAddDialog();resetValuesDialogExternalUser()"
              >Close</v-btn>
              <v-btn
                color="green"
                text
                @click="addExternalUser()"
              >Save</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import PasswordField from '../../StandardizedComponents/passwordFields'
import TextField from '../../StandardizedComponents/textFields'

export default {
  components: {
    PasswordField,
    TextField
  },
  computed: {
    ...mapState("ExternalUsers", ["addExternalUserDialog","dialogValuesExternalUser","passwordRules"])
  },
  methods:{
    ...mapMutations("ExternalUsers", ["setUsername","setPassword","setRepeatedPassword","resetValuesDialogExternalUser","closeExternalUserAddDialog","resetValuesDialogExternalUser"]),
    ...mapActions("ExternalUsers", ["addExternalUser", "generateRandomPassword","getTableExternalUsers"]),
  }
}
</script>