<template>
  <div id="app">
    <v-app id="inspire">
        <v-data-table
        :headers="externalUsersTable.headers"
        :items="externalUsersTable.ExtUsrs"
        item-key="ext_usr_id"
        class="elevation-1"
        :loading="externalUsersTable.loading"
        loading-text="Cargando..."
        no-data-text="No data found"
        dense
        :search="externalUsersTable.search"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-row>
                        <v-spacer></v-spacer>
                        <SearchBar :setSearchTerm="setTableSearchTerm"/> 
                    </v-row>
                </v-toolbar>
            </template>

            <template v-slot:item.acciones="{ item }">
              <TableButtons
                :Buttons="[
                    {
                        icon:'mdi-pencil',
                        Fn:showExternalUserEditDialog,
                        fnParams:item
                    },
                    {
                        icon:'mdi-delete',
                        Fn:showExternalUserDeleteDialog,
                        fnParams:item
                    } 
                ]"
              />
            </template>
        </v-data-table>
        <DialogAdd/>
        <DialogDelete/>
        <DialogEdit/>
        <FAB :buttons="[{
          fn: showExternalUserAddDialog,
          color:'primary',
          icon: 'mdi-plus',
          name: 'Agregar usuario externo'
          }]"/>
    </v-app>
  </div>
</template>
<script>
import {  mapActions, mapMutations, mapState } from "vuex";
import TableButtons from '../Complements&Buttons/tableButtons'

import DialogAdd from  '../Dialogs/ExtUsersDialogs/extUserAdd'
import DialogEdit from '../Dialogs/ExtUsersDialogs/editDialog'
import DialogDelete from '../Dialogs/ExtUsersDialogs/deleteDialog'
import SearchBar from '../Complements&Buttons/searchBar'
import FAB from '../Complements&Buttons/fab'

export default {
  data: () => ({
    hidden: false,
  }),
  components: {
    TableButtons,
    DialogEdit,
    DialogAdd,
    DialogDelete,
    SearchBar,
    FAB,
  },
  computed: {
    ...mapState("ExternalUsers", [
      "externalUsersTable"
    ]),
  },

  methods: {
    ...mapMutations("ExternalUsers", [
      "setTableSearchTerm",
      "showExternalUserAddDialog",
      "showExternalUserEditDialog",
      "showExternalUserDeleteDialog"
    ]),
    ...mapActions("ExternalUsers", ["getTableExternalUsers"])
  },
  created() {
    this.getTableExternalUsers();
  },
};
</script>