<template>
    <v-dialog
        :value="deleteInternalUserDialog.dialog"
        max-width="600px"
        :persistent="true"
    >
        <v-card max-width="600px">
            <v-card-title class="headline">
                ¿Estás seguro que quieres eliminar este
                usuario?
            </v-card-title>
            <v-card-text>
                <v-row class="mx-0 ;align=center">
                <v-card width="550px"  >
                    <v-card-title class="subheading font-weight-bold">
                    ID de usuario: {{ deleteInternalUserDialog.id }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-list dense>
                    <v-list-item>
                        <v-list-item-content>Correo electronico:</v-list-item-content>
                        <v-list-item-content class="align-center">
                        {{ deleteInternalUserDialog.email }}
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Nombre:</v-list-item-content>
                        <v-list-item-content class="align-center">
                        {{ deleteInternalUserDialog.name }}
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDeleteInternalUserDialog();resetDeleteInternalUserDialog()"
                    >Cancel</v-btn
                >
                <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteInternalUser"
                    >OK</v-btn
                >
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
    computed: {
        ...mapState("InternalUsers", ["deleteInternalUserDialog"])
    },
    methods: {
        ...mapMutations("InternalUsers", ["resetDeleteInternalUserDialog", "closeDeleteInternalUserDialog"]),
        ...mapActions("InternalUsers", ["deleteInternalUser"])
    },
}
</script>