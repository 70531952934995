var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.internalUsersTable.headers,"items":_vm.internalUsersTable.usersTable,"item-key":"id_user","loading":_vm.internalUsersTable.loading,"loading-text":"Cargando...","no-data-text":"No data found","dense":"","search":_vm.internalUsersTable.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-spacer'),_c('SearchBar',{attrs:{"setSearchTerm":_vm.setTableSearchTerm}})],1)],1)]},proxy:true},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('TableButtons',{attrs:{"Buttons":[
              {
                  icon:'mdi-pencil',
                  Fn:_vm.showEditInternalUserDialog,
                  fnParams:item
              },
              {
                  icon:'mdi-delete',
                  Fn:_vm.showDeleteInternalUserDialog,
                  fnParams:item
              } 
          ]}})]}}])}),_c('DialogAdd'),_c('DialogDelete'),_c('DialogEdit'),_c('FAB',{attrs:{"buttons":[{
        fn: _vm.showAddInternalUserDialog,
        color:'primary',
        icon: 'mdi-plus',
        name: 'agregar usuario',
      }]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }