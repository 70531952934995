<template>
    <div>
        <v-icon v-for="(button,index) in Buttons" :key="index" small class="mr-2" @click="button.Fn(button.fnParams)">
            {{button.icon}}
        </v-icon>
    </div>
</template>
<script>
    export default {
        props:{
            Buttons: Array,
        }
    }
</script>